import { useEffect } from 'react';
import { DefaultPalette, IColumn } from '@fluentui/react';
import { fiscalQuarter } from '../models/appModels/fiscalMonths';
import moment from 'moment';
import { commonStyles } from './common.styles';

/**
 * Common properties used on IColumn. Can be mixed in using ...commonColumnProps.
 * Any of these can be overridden while mixed in by supplying the property again.
 */
export const commonColumnProps: any = {
    isRowHeader: false,
    isResizable: true,
    isPadded: true,
    isCollapsible: false,
    isMultiline: true,
    headerClassName: `${commonStyles.wordWrapColumnHeader}`
};

/**
 * Use mount effect. This uses the second useEffect param of [] to ensure the function only runs on mount.
 * From: https://stackoverflow.com/questions/53120972/how-to-call-loading-function-with-react-useeffect-only-once
 * @param func Function to run once after page mount.
 */
// eslint-disable-next-line
export const useMountEffect = (func: () => void) => useEffect(func, []);

/**
 * Copy and sort items.
 * @param items Items to sort.
 * @param columnKey Column key.
 * @param isSortedDescending Is sorted descending or not.
 * @returns Sorted array.
 */
const copyAndSort = <T extends unknown>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] => {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
};

export interface ColumnsAndItems<T> {
    columns: IColumn[];
    items: T[];
    currentSortedColumn: IColumn;
}

/**
 * Sort columns on column specified.
 * @param column Column to sort on.
 * @param columns All the columns.
 * @param items Items to sort.
 * @param isSortedDescending Optional: If supplied, sort this way. Otherwise invert the sort.
 * @returns Updated columns collection sorted column isSorted set to true.
 */
export const sortOnColumn = <T extends unknown>(column: IColumn, columns: IColumn[], items: T[], isSortedDescending?: boolean): ColumnsAndItems<T> => {
    if (!items || !column) {
        // Return the columns and items that was passed as input.
        return { columns: columns, items: items, currentSortedColumn: column };
    }

    const newColumns: IColumn[] = [...columns]; // Copy columns from state into new array using spread operator.
    const curColumn: IColumn = newColumns.filter(c => column.key === c.key)[0];
    if (!curColumn) {
        // If the column could not be found in the new columns, then return original columns and items.
        return { columns: columns, items: items, currentSortedColumn: column };
    }
    newColumns.forEach((newCol: IColumn) => {
        if (newCol === curColumn) {
            curColumn.isSorted = true;
            curColumn.isSortedDescending = isSortedDescending === undefined || isSortedDescending === null ? !curColumn.isSortedDescending : isSortedDescending;
        } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
        }
    });
    const newItems: T[] = [
        ...copyAndSort(items, curColumn.fieldName!, curColumn.isSortedDescending)
    ];

    return { columns: newColumns, items: newItems, currentSortedColumn: curColumn };
};

/**
 * Reset column sorting on columns.
 * @param columns Array of columns.
 * @returns Modified columns array.
 */
export const resetColumnSorting: (columns: IColumn[]) => IColumn[] = (columns) => {
    columns.forEach((column: IColumn) => {
        column.isSorted = undefined;
        column.isSortedDescending = undefined;
    });
    return columns;
};

/**
 * Gets the current fiscal year and prior two fiscal years.
 * @returns Fiscal years.
 */
export const getFiscalYears: () => number[] = () => {
    let fiscalYears: number[];

    const now: Date = nowDate();
    const month: number = now.getMonth();
    const year: number = now.getFullYear();
    if (month < 6) {
        fiscalYears = [year, year - 1, year - 2];
    } else {
        fiscalYears = [year + 1, year, year - 1];
    }

    return fiscalYears;
};

/**
 * Gets the current calendar year and prior two calendar years.
 * @returns Calendar years.
 */
export const getCalendarYears: () => number[] = () => {
    const now: Date = nowDate();
    const year: number = now.getFullYear();
    const calendarYears: number[] = [year, year - 1, year - 2];
    return calendarYears;
};

/**
 * Start and end date.
 */
export interface StartEndDate {
    startDate: Date;
    endDate: Date;
}

/**
 * Determine start and end date based on fiscal input range.
 * @param fy Fiscal year.
 * @param fq Fiscal quarter.
 * @param fm Fiscal month.
 * @returns Start and end date.
 */
export const determineStartAndEndDateFromFiscalRange = (fy: number, fq: string | number | undefined, fm: number | undefined): StartEndDate => {
    let startCalendarYear: number;
    let startCalendarMonth: number;
    let endCalendarYear: number;
    let endCalendarMonth: number;

    // Fiscal year cannot be null, UI enforces this. Thus the use of the null assertion operator: selectedFiscalYear!

    // If fiscal quarter was selected, then set the start calendar year to be the prior or current year
    // from selected fiscal year, depending on fiscal quarter selected. Default the start calendar month
    // to the proper month based on the selected fiscal quarter.
    switch (fq) {
        case fiscalQuarter.q1: {
            // July 1st of the prior year from selected fiscal year to
            // October 1st of that same year.
            startCalendarMonth = 7;
            startCalendarYear = fy - 1;
            endCalendarMonth = 10;
            endCalendarYear = startCalendarYear;
            break;
        }
        case fiscalQuarter.q2: {
            // October 1st of the prior year from the selected fiscal year to
            // January 1st of the next year.
            startCalendarMonth = 10;
            startCalendarYear = fy - 1;
            endCalendarMonth = 1;
            endCalendarYear = startCalendarYear + 1;
            break;
        }
        case fiscalQuarter.q3: {
            // January 1st of the same year as the selected fiscal year to
            // April 1st of that same year.
            startCalendarMonth = 1;
            startCalendarYear = fy;
            endCalendarMonth = 4;
            endCalendarYear = startCalendarYear;
            break;
        }
        case fiscalQuarter.q4: {
            // April 1st of the same year as the selected fiscal year to
            // July 1st of that same year.
            startCalendarMonth = 4;
            startCalendarYear = fy;
            endCalendarMonth = 7;
            endCalendarYear = startCalendarYear;
            break;
        }
        default: {
            // No fiscal quarter selected, so use full fiscal year.
            // July 1st of the prior year from selected fiscal year to
            // July 1st of the next year.
            startCalendarMonth = 7;
            startCalendarYear = fy - 1;
            endCalendarMonth = 7;
            endCalendarYear = startCalendarYear + 1;
            break;
        }
    }

    // If the user choose a specific fiscal month then set the start calendar month to equal the fiscal month
    // and the end calendar month to be the following month.
    if (fm) {
        startCalendarMonth = fm;
        if (startCalendarMonth < 12) {
            endCalendarMonth = startCalendarMonth + 1;
            endCalendarYear = startCalendarYear;
        } else {
            // Set to January of the next year.
            endCalendarMonth = 1;
            endCalendarYear = startCalendarYear + 1;
        }
    }

    // Time element will always be midnight (00:00).
    const startEndDate: StartEndDate = {
        startDate: new Date(startCalendarYear, startCalendarMonth - 1 /* month is 0 based */, 1 /* First of the month. */),
        endDate: new Date(endCalendarYear, endCalendarMonth - 1 /* month is 0 based */, -1 /* Last day of prior month. */)
    }

    return startEndDate;
};

/**
 * Selection of misc colors from the Fluent default palatte colors. There are more colors available, this
 * is just a selection of them. Ordered so similar colors are not next to each other, so they can be iterated
 * through and used on a chart with lots of colors.
 */
export const colors = [
    DefaultPalette.blue,
    DefaultPalette.magenta,
    DefaultPalette.teal,
    DefaultPalette.purple,
    DefaultPalette.orange,
    DefaultPalette.green,
    DefaultPalette.red,
    DefaultPalette.blueMid,
    DefaultPalette.magentaDark,
    DefaultPalette.tealDark,
    DefaultPalette.purpleDark,
    DefaultPalette.greenDark,
    DefaultPalette.redDark,
    DefaultPalette.yellowDark
];

/**
 * Return a random color from the Fluent default palatte colors.
 */
export const randomColor = () => {
    return colors[Math.round(Math.random() * (colors.length - 1))];
};

let colorIndex: number = 0;

/**
 * Returns next color index when incrementing through colors.
 * @param startingIndex An optional value indicating the index from which to get the next color index.
 */
export const nextColorIndex = (startingIndex?: number): number => {
    if (startingIndex || startingIndex === 0) {
        colorIndex = startingIndex;
    }
    colorIndex++;
    if (colorIndex > colors.length - 1) {
        colorIndex = 0;
    }
    return colorIndex;
};

/**
 * Check if variable is null or undefined. 
 * Not checking for empty string or 0 (both are falsey).
 */
export const isNullOrUndefined = (x?: any): boolean => {
    if (x === null) {
        return true;
    } else if (x === undefined) {
        return true;
    }
    return false;
};

/**
 * Check if variable is null or undefined or empty string.
 * Does not check for 0 (which is falsey).
 */
export const isNullOrUndefinedOrEmptyString = (x?: any): boolean => {
    if (isNullOrUndefined(x)) {
        return true;
    } else if (typeof x === 'string' && x.trim() === '') {
        return true;
    }
    return false;
};

/**
 * Returns the date as of now.
 */
export const nowDate = (): Date => {
    return new Date();
}

/**
 * Returns the date from a week ago.
 */
export const weekAgoDate = (): Date => {
    return new Date(nowDate().valueOf() - (1000 * 60 * 60 * 24 * 7));
};

/**
 * Returns the date from one month ago.
 */
export const oneMonthAgoDate = (): Date => {
    return moment().subtract(1, 'months').toDate();
};

/**
 * Returns the date from three months ago.
 */
export const threeMonthsAgoDate = (): Date => {
    return moment().subtract(3, 'months').toDate();
};

/**
 * Adds days to a date.
 * @param date Date to add days to.
 * @param days Number of days to add.
 */
export const datePlusDays = (date: Date, days: number): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
};

/**
 * Returns the month and year as a string.
 * @param offsetMonths Offset months. Nullable. If not supplied then current month. -1 returns prior month. 1 returns next month, etc...
 */
export const monthAndYearString = (offsetMonths?: number): string => {
    return moment().add(offsetMonths ? offsetMonths : 0, 'month').format('MMMM YYYY');
};

/**
 * Returns the date for the start of the month offset from the current month.
 * @param offsetMonths Offset months. Nullable. If not supplied then current month. -1 returns prior month. 1 returns next month, etc...
 */
export const startOfMonthOffset = (offsetMonths?: number): Date => {
    return moment().add(offsetMonths ? offsetMonths : 0, 'month').startOf('month').startOf('day').toDate();
};

/**
 * Returns the date for the end of the month offset from the current month.
 * @param offsetMonths Offset months. Nullable. If not supplied then current month. -1 returns prior month. 1 returns next month, etc...
 */
export const endOfMonthOffset = (offsetMonths?: number): Date => {
    return moment().add(offsetMonths ? offsetMonths : 0, 'month').endOf('month').endOf('day').toDate();
};

/**
 * Create a new guid.
 * @returns New guid string.
 */
export const createGuid = (): string => {
    const guidPattern: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    return guidPattern.replace(/[xy]/g, c => {
        // eslint-disable-next-line no-mixed-operators
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

/**
 * Checks if the Guid is valid.
 * @param guid Guid
 */
export const isValidGuid = (guid: string): boolean => {
    const regex: RegExp = new RegExp(/^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/);
    return regex.test(guid);
};

/**
 * Checks if the string is alphanumeric with - or _ or space allowed.
 * @param str String to test.
 */
export const isValidAlphaNumericWithDashOrUnderscoreOrSpace = (str: string): boolean => {
    const regex: RegExp = new RegExp(/^[a-zA-Z0-9-_ ]+$/);
    return regex.test(str);
};

/**
 * Checks if an element is scrollable (scroll bar present).
 * @param elem Html element.
 * @returns True if element is scrollable (either horizontal or vertical), otherwise false.
 */
export const isScrollable = (elem: HTMLElement | null): boolean => {
    if (!elem) {
        return false;
    }

    // The scrollTop property sets or returns the vertical scrollbar position for the selected elements.
    const y1: number = elem.scrollTop;
    elem.scrollTop += 1;
    const y2: number = elem.scrollTop;
    elem.scrollTop -= 1;
    const y3: number = elem.scrollTop;
    elem.scrollTop = y1;

    // The scrollLeft property sets or returns the horizontal scrollbar position for the selected elements.
    const x1: number = elem.scrollLeft;
    elem.scrollLeft += 1;
    const x2: number = elem.scrollLeft;
    elem.scrollLeft -= 1;
    const x3: number = elem.scrollLeft;
    elem.scrollLeft = x1;

    return (x1 !== x2 || x2 !== x3) || (y1 !== y2 || y2 !== y3);
};

/**
* Converts input properties of { string : object } pair to flat array.
* @param inputProps Input properties of { string : object } pair.
*/
export const flatProperties = (inputProps: { [key: string]: unknown } | undefined): string[] | undefined => {
    if (!inputProps) {
        return undefined;
    }

    const retVal: string[] = [];
    Object.keys(inputProps).forEach(function (key: string, index: number) {
        retVal[index] = key + ':' + String(inputProps[key]);
    });

    return retVal;
};

/**
 * Assign the onColumnClick event on all columns.
 * @param cols Columns to apply the callback to.
 * @param onColumnClick Callback for when the user clicks on the column header.
 */
export const assignOnColumnClick = (cols: IColumn[], onColumnClick?: (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void) => {
    if (cols && cols.length > 0) { 
        cols.forEach(c => c.onColumnClick = onColumnClick);
    }
};

/**
 * Rename key in an object.
 * Generic types are inferred, do not pass them in.
 * See: https://stackoverflow.com/questions/4647817/javascript-object-rename-key
 * @param oldKey Old key.
 * @param newKey New key.
 * @param obj Object to have a key renamed. 
 */
export const renameKey = <OldKey extends keyof T, NewKey extends string, T extends Record<string, unknown>>(
    oldKey: OldKey,
    newKey: NewKey extends keyof T ? never : NewKey,
    obj: T
): Record<NewKey, T[OldKey]> & Omit<T, OldKey> => {
    const { [oldKey]: value, ...common } = obj
    return {
        ...common,
        ...({ [newKey]: value } as Record<NewKey, T[OldKey]>)
    }
};

/**
 * Reload the site.
 */
export const reloadSite = (): void => {
    // Reload the site. Using window.top here in case this code is running in the MSAL hidden iframe used with silent token acquisition.
    window.top?.location.reload();
};
